<template>
    <dashboard-layout>
        <v-card class="mt-4" width="100%">
            <v-card-title>
                <v-row>
                    <v-col>
                        <h3>Choose your instruments</h3>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col cols="12" md="5">
                            <v-text-field
                                label="Add your own instrument"
                                :error-messages="newInstrumentErr"
                                v-model="newInstrument"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn @click="addNewInstrument">
                                Add
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-for="instrument in instruments" :key="instrument.name" cols="12" md="4" lg="3">
                            <v-checkbox
                                v-model="instrumentsSelected"
                                :label="instrument.name"
                                :value="instrument.name"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="save">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import Instrument from "@/models/Instrument";
import DashboardLayout from "@/layouts/DashboardLayout";
import {mapMutations} from "vuex";

export default {
    name: "users-show",
    components: {DashboardLayout,},
    data: function () {
        return {
            instruments: [],
            instrumentsSelected: [],
            newInstrument: null,
            newInstrumentErr: null,
        }
    },
    methods: {
        async save() {
            this.newInstrumentErr = null
            let msg = 'Equipment saved successfully';
            let color = 'success'

            try {
                await window.axios.put('/api/instruments/sync', {
                    instruments: this.$_.map(this.instrumentsSelected, eq => eq instanceof Instrument ? eq.name : eq)
                })
            } catch (e) {
                msg = 'Something went wrong, please try again later.'
                color = 'error'
            }

            this.showSnackBar({color: color, timeout: 3000, text: msg})
        },
        addNewInstrument() {
            this.newInstrumentErr = null

            if (!this.newInstrument) {
                this.newInstrumentErr = "Please fill this field"
                return
            }

            let instrument = this.$_.find(this.instruments, i => i.name.toLowerCase() === this.newInstrument.toLowerCase())

            if (!instrument) {
                instrument = new Instrument({name: this.newInstrument})
                this.instruments.push(instrument)
            }

            this.instrumentsSelected.push(instrument.name)
            this.instrumentsSelected = [...new Set(this.instrumentsSelected)]

            this.newInstrument = null
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        this.instruments = await Instrument.get();
        this.instrumentsSelected = this.$_.map(await Instrument.where('user', this.$auth.user().id).get(), eq => eq.name)
    }
}
</script>

<style scoped>

</style>
